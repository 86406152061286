import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useResource } from '@tg/core/hooks';
import {
  Heading,
  Breadcrumbs,
  Tabs,
  Segment,
  ListInline,
  Message,
} from '@tg/core/components';
import LineManager from 'src/components/Contracts/LineManager';
import ContractSalaries from 'src/components/Contracts/ContractSalaries';
import ContractDocuments from 'src/components/Contracts/ContractDocuments';
import ContractPayslips from 'src/components/Contracts/ContractPayslips';
import ContractJobTitles from 'src/components/Contracts/ContractJobTitles';
import ContractTermination from 'src/components/Contracts/ContractTermination';
import Planners from 'src/components/Planners';
import ContractBenefits from 'src/components/Contracts/ContractBenefits';
import ActionItem from 'src/components/ActionItem';
import ContractorInvoices from 'src/components/Contracts/ContractorInvoices';
import ContractCalendar from 'src/components/Contracts/ContractCalendar';
import { formatGrossSalary } from '@tg/core/utils/stringHelpers';
import { formatDate } from '@tg/core/utils/datetimeHelpers';
import permissions from '@tg/core/constant';
import { getPermission } from '@tg/core/utils/permissionHelpers';
import { useSelector } from 'react-redux';
import ContractOverview from '../../../../Contracts/ShowContract';

const {
  EMPLOYER_DETAIL_VIEW_EMPLOYEES_CONTRACT: {
    OVERVIEW: {
      CONTRACT_OVERVIEW,
      CONTRACT_OVERVIEW_SECTION,
      LINE_MANAGER_VIEW,
      CONTRACT_JOB_TITLES_VIEW,
      CONTRACT_DOCUMENTS_VIEW,
      SALARY_HISTORY_VIEW,
      TERMINATE_CONTRACT,
    },
  },
  EMPLOYER_DETAIL_VIEW_EMPLOYEE: {
    TIME_OFF_VIEW,
    BENEFITS_VIEW,
    PAYROLL_MENU_OPTION,
    CONTRACT_OVERVIEW: { VIEW_EMPLOYEE_LINK },
  },
  EMPLOYEE_DETAIL: { SETUP_CHECK_LIST },
} = permissions;

const ContractDetails = () => {
  const { contractId, employerId } = useParams();
  const accesslist = useSelector(state => state?.access?.roles);

  const [ui, setUI] = useState(null);
  const { data, isFetching, getResource, patchResource } = useResource(
    { url: `contracts/${contractId}` },
    true,
  );

  const { data: dataList, patchResource: terminateContract } = useResource({
    url: `contracts/${contractId}/update_contract`,
  });

  const {
    employee,
    current_salary,
    current_job_title,
    approved_at,
    employee_signature_requested_at,
    employee_signed_at,
    contractor,
    job_title,
  } = data || {};
  const employee_name = employee?.user?.full_name;

  const [activeItem, setActiveItem] = useState('overview');
  const tabs = [
    ...(getPermission(accesslist, CONTRACT_OVERVIEW)
      ? [{ text: 'Contract Overview', id: 'overview' }]
      : []),
    ...(getPermission(accesslist, PAYROLL_MENU_OPTION)
      ? [{ text: 'Payroll', id: 'payroll' }]
      : []),
    ...(getPermission(accesslist, BENEFITS_VIEW)
      ? [{ text: 'Benefits', id: 'benefits' }]
      : []),
    ...(getPermission(accesslist, TIME_OFF_VIEW)
      ? [{ text: 'Time Off', id: 'time-off' }]
      : []),
  ];
  if (contractor) {
    tabs.push({
      text: 'Contractor Invoice',
      id: 'contractor-invoices',
    });
  }

  useEffect(() => {
    if (getPermission(accesslist, CONTRACT_OVERVIEW))
      return setActiveItem('overview');
    if (getPermission(accesslist, PAYROLL_MENU_OPTION))
      return setActiveItem('payroll');
    if (getPermission(accesslist, BENEFITS_VIEW))
      return setActiveItem('benefits');
    return setActiveItem('time-off');
  }, [contractId]);

  const currentSalary = current_salary
    ? `${current_salary.currency.code} ${formatGrossSalary(
        current_salary.gross_salary,
      )}`
    : '';

  return (
    <>
      <header>
        <Breadcrumbs
          items={[
            { to: `/employers/${employerId}/employees`, text: 'Employees' },
            { text: employee_name },
          ]}
        />
        <div className='mb-2 mt-4 flex justify-between'>
          <Heading level='h3' zeropad>
            {employee_name}
          </Heading>
          {getPermission(accesslist, VIEW_EMPLOYEE_LINK) && (
            <>
              {employee && (
                <Link to={`/employees/${employee.id}`} className='link'>
                  View Employee
                </Link>
              )}
            </>
          )}
        </div>
        <ListInline
          items={[
            <>
              Job title: <span className='font-semibold'>{job_title}</span>
            </>,
            <>
              Current Salary:{' '}
              <span className='font-semibold'>{currentSalary}</span>
            </>,
          ]}
        />
        {data && data.termination_date && (
          <div className='mt-2'>
            <Message type='warning' title='Contract Terminated'>
              <p>{formatDate({ dateStr: data.termination_date })}</p>
              <p>{data.contract_termination_reason.name}</p>
            </Message>
          </div>
        )}
      </header>
      <Tabs
        items={tabs}
        onItemClick={id => {
          setActiveItem(id);
        }}
        activeItem={activeItem}
      />
      {activeItem === 'overview' && (
        <>
          {getPermission(accesslist, SETUP_CHECK_LIST) && (
            <Segment heading='Setup checklist'>
              <ActionItem
                url={`contracts/${contractId}/approve`}
                timestamp={approved_at}
                checklist
              >
                Check contract setup data
              </ActionItem>

              <ActionItem
                url={`contracts/${contractId}/mark_sent_for_signature`}
                timestamp={employee_signature_requested_at}
                checklist
              >
                Log contract has been sent to employee
              </ActionItem>

              <ActionItem
                url={`contracts/${contractId}/mark_contract_signed`}
                timestamp={employee_signed_at}
                checklist
              >
                Log contract has been signed by employee
              </ActionItem>
            </Segment>
          )}
          {data && (
            <>
              {getPermission(accesslist, CONTRACT_OVERVIEW_SECTION) && (
                <Segment heading='Contract Overview'>
                  <ContractOverview
                    onSubmit={patchResource}
                    data={data}
                    isFetching={isFetching}
                  />
                </Segment>
              )}
              {getPermission(accesslist, LINE_MANAGER_VIEW) && (
                <Segment heading='Line Manager' flush>
                  <LineManager contract={data} />
                </Segment>
              )}
            </>
          )}

          {getPermission(accesslist, CONTRACT_JOB_TITLES_VIEW) && (
            <Segment heading='Contract job titles'>
              {current_job_title && (
                <ContractJobTitles
                  currentJobTitle={current_job_title}
                  getResource={getResource}
                />
              )}
            </Segment>
          )}
          {getPermission(accesslist, CONTRACT_DOCUMENTS_VIEW) && (
            <Segment heading='Contract documents'>
              <ContractDocuments />
            </Segment>
          )}
          {getPermission(accesslist, SALARY_HISTORY_VIEW) && (
            <Segment heading='Salary History'>
              {current_salary && (
                <ContractSalaries
                  currentSalary={current_salary}
                  getResource={getResource}
                />
              )}
            </Segment>
          )}
          {getPermission(accesslist, TERMINATE_CONTRACT) && (
            <Segment heading='Terminate Contract' flush>
              <ContractTermination
                patchResource={terminateContract}
                data={dataList || data}
              />
            </Segment>
          )}
        </>
      )}

      {activeItem === 'payroll' && (
        <Segment heading='Payslips'>
          <ContractPayslips />
        </Segment>
      )}
      {activeItem === 'benefits' && (
        <Segment heading='Benefits'>
          <ContractBenefits />
        </Segment>
      )}
      {activeItem === 'time-off' && (
        <>
          {getPermission(accesslist, TIME_OFF_VIEW) && (
            <>
              <Segment heading='Planner Years'>
                {data && <Planners contract={data} setUI={setUI} />}
              </Segment>
              <Segment heading='Calendar'>
                {data && <ContractCalendar contract={data} ui={ui} />}
              </Segment>
            </>
          )}
        </>
      )}
      {activeItem === 'contractor-invoices' && (
        <Segment heading='Contractor Invoices'>
          {data && <ContractorInvoices />}
        </Segment>
      )}
    </>
  );
};

export default ContractDetails;
